import Cookies from 'js-cookie'
import { COMFINO_SID_KEY } from '~/utils/consts'
import processUnauthorized from '~/utils/session-remover'

export default function ({ $axios, store, route, redirect }, inject) {
    $axios.defaults.baseURL = process.env.API_CUSTOMER_ORIGIN
    $axios.onRequest(config => {
        config.headers.common[COMFINO_SID_KEY] = Cookies.get(COMFINO_SID_KEY)
        const { unlockToken } = store.state
        if (unlockToken) {
            config.headers.common['Unlock-Token'] = unlockToken
        }
    })

    $axios.interceptors.request.use((request) => {
        if (store.state.isWindowActive === false) {
            // blocking a request that is being sent causes unhandled nuxt errors
            // so we have to blank out the request to avoid errors
            return {
                ...request,
                baseURL: '',
                url: '',
            }
        }
        return request
    })

    $axios.interceptors.response.use((response) => response, (error) => {
        if (process.env.NODE_ENV === 'development') {
            console.error(error)
        }

        const code = parseInt(error.response && error.response.status)
        if (error.request) {
            error.message = error.request.responseURL + ' returned ' + code
        }

        if (code === 401) {
            processUnauthorized(redirect, route.query.token)
        } else if (code === 403) {
            location.reload()
        }

        return Promise.reject(error)
    })
}

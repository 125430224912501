import * as Sentry from '@sentry/browser'

export default async function updateCustomerAccount ($accountAxios, store, $popup, token) {
    try {
        const response = await $accountAxios.get(`/account/order/${token}/customer`)
        const customerData = response.data

        store.commit('setCustomerAccount', customerData)
    } catch (error) {
        Sentry.captureException(error)
        $popup.error({
            title: 'Nie udało się pobrać danych z konta',
            text: 'Zajmujemy się naprawą błędu. Nie przejmuj się, nadal możesz uzupełnić wniosek aktualnymi danymi.',
        })
    }
}
